import Head from 'next/head';
import { ThemeProvider } from 'next-themes';
import { DefaultSeo } from 'next-seo';
import { appWithTranslation } from 'next-i18next';

import { SEO } from '@/components';
import LayoutWrapper from '@/layouts/LayoutWrapper';

import '@/css/tailwind.css';
import '@/css/main.css';
import '@/css/sudoku.scss';
import 'highlight.js/styles/darcula.css';

const MyApp = ({ Component, pageProps }: { Component: any; pageProps: any }) => {
	return (
		<ThemeProvider attribute='class'>
			<Head>
				<meta content='width=device-width, initial-scale=1' name='viewport' />
			</Head>
			<DefaultSeo {...SEO} />
			<LayoutWrapper>
				<Component {...pageProps} />
			</LayoutWrapper>
		</ThemeProvider>
	);
};

export default appWithTranslation(MyApp);
