import { useTranslation } from 'next-i18next';
import {
	Link,
	SectionContainer,
	Footer,
	MobileNav,
	ThemeSwitch,
	LocaleSwitch,
} from '../components/index';
import config from '@/config';

const headerNavLinks = [
	{ href: '/blog', title: 'blog' },
	// { href: '/tags', title: 'Tags' },
	{ href: '/games', title: 'games' },
	{ href: '/about', title: 'about' },
];

export default function LayoutWrapper({ children }) {
	const { t } = useTranslation('common');

	return (
		<SectionContainer>
			<div className='flex flex-col justify-between h-screen'>
				<header className='flex items-center justify-between py-6 border-b-2'>
					<div>
						<Link href='/' aria-label='Kramar Blog'>
							<div className='flex items-center justify-between'>
								<div className='mr-3'>
									<svg
										className='w-8 text-teal-accent-400 text-green-500'
										viewBox='0 0 24 24'
										strokeLinejoin='round'
										strokeWidth={2}
										strokeLinecap='round'
										strokeMiterlimit={10}
										stroke='currentColor'
										fill='none'
									>
										<rect x={3} y={1} width={7} height={12} />
										<rect x={3} y={17} width={7} height={6} />
										<rect x={14} y={1} width={7} height={6} />
										<rect x={14} y={11} width={7} height={12} />
									</svg>
								</div>
								{typeof config.headerTitle === 'string' ? (
									<div className='hidden h-6 text-xl font-semibold sm:block'>
										{config.headerTitle}
									</div>
								) : (
									config.headerTitle
								)}
							</div>
						</Link>
					</div>
					<div className='flex items-center text-base leading-5'>
						<div className='hidden sm:block'>
							{headerNavLinks.map((link) => (
								<Link
									key={link.title}
									href={link.href}
									locale='fr'
									className='p-1 font-medium text-gray-900 sm:p-4 dark:text-gray-100'
								>
									{t(link.title)}
								</Link>
							))}
						</div>
						<ThemeSwitch />
						<LocaleSwitch />
						<MobileNav />
					</div>
				</header>
				<main className='mb-auto '>{children}</main>
				<Footer />
			</div>
		</SectionContainer>
	);
}
